import React from "react"
import { FaTag } from "react-icons/fa/";
import PropTypes from "prop-types"
import { ThemeContext } from "../layouts";
import Article from "../components/Article/";
import Headline from "../components/Article/Headline";
// Components
import { Link, graphql } from "gatsby"

const diets = ({ pageContext, data }) => {
  const { diet } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  const dietHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } tagged with "${diet}"`

  return (
    <React.Fragment>
      <ThemeContext.Consumer>
        {theme => (
          <Article theme={theme}>
            <header>
              <Headline theme={theme}>
              <span>Recipes with</span> <FaTag />
                {diet}
              </Headline>
        {edges.map(({ node }) => {
          const {title } = node.frontmatter
          var path = node.fields.slug
          return (
            <li key={path}>
              <Link to={path}>{title}</Link>
            </li>
          )
        })}
        </header>
        <hr/>
        
       <Link to="/diets">All diets</Link>
           </Article>
         )}
       </ThemeContext.Consumer>

     </React.Fragment>


  )
}

diets.propTypes = {
  pathContext: PropTypes.shape({
    diet: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              path: PropTypes.string.isRequired,
              title: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default diets

export const pageQuery = graphql`
  query($diet: String) {
    allMarkdownRemark(
      limit: 2000
      filter: { frontmatter: { diet: { in: [$diet] } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            author
          }
          fields{
            slug
          }
        }
      }
    }
  }
`
